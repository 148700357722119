import { useEffect, useRef, useState } from 'react'
import type { ReactElement } from 'react'
import { Button, Col, Row, Modal, Form, Input, Typography } from 'antd'
import getYouTubeID from 'get-youtube-id'
import YouTube from 'react-youtube'
import TitleStep from '../../../../components/TitleStep'
import { getVkEmbedLink, getYoutubeCover } from '../../../../utils/helpers'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import NotFoundStep from '../../../../components/NotFoundStep'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import { companyWizardSelector } from '../../selectors'
import NextStepButton from '../../../../components/NextStepButton'

const { Paragraph } = Typography

// Styles
import styles from './styles.module.scss'

interface IVideo {
    id: number
    url: string
}

export default function VideoGalleryStep(): ReactElement {
    const [isAddModal, setIsAddModal] = useState(false)
    const [isVideoModal, setIsVideoModal] = useState<false | string>(false)
    const [isVideoVkModal, setIsVideoVkModal] = useState<false | string>(false)
    const [videos, setVideos] = useState<IVideo[]>([])

    const [form] = Form.useForm()

    const refYouTube = useRef<YouTube>(null)

    const dispatch = useDispatch()

    const { currentCompany, isSavingVideo, removingVideos } = useAppSelector(
        companyWizardSelector
    )

    useEffect(() => {
        if (currentCompany && Array.isArray(currentCompany.videos)) {
            setVideos(
                currentCompany.videos.map(video => ({
                    id: video.id,
                    url: video.url
                }))
            )
        }
    }, [currentCompany])

    const handleAddVideo = () => {
        const url = form.getFieldValue('url')

        const regYouTube =
            /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/
        const regVkVideo = /https:\/\/(vk\.com|vkvideo\.ru).*video-\d+_\d+/

        if (regYouTube.test(url) || regVkVideo.test(url)) {
            form.resetFields()
            setIsAddModal(false)
            if (currentCompany) {
                dispatch(
                    actions.saveVideo({
                        company_id: currentCompany.company_id,
                        videos: [url]
                    })
                )
            }
        } else {
            form.setFields([
                {
                    name: 'url',
                    value: url,
                    errors: ['Не верно введена ссылка на видео']
                }
            ])
        }
    }

    const handleRemoveVideo = (video_id: number) => {
        if (currentCompany) {
            dispatch(
                actions.removeVideo({
                    company_id: currentCompany.company_id,
                    media_id: video_id
                })
            )
        }
    }

    return (
        <>
            <TitleStep
                rightElement={
                    <Button
                        disabled={removingVideos.length > 0}
                        onClick={() => setIsAddModal(true)}
                        icon={<PlusOutlined />}
                        loading={isSavingVideo}
                    >
                        {'Добавить видео'}
                    </Button>
                }
            >
                {'Видеогалерея'}
            </TitleStep>
            <Paragraph style={{ fontSize: 12 }} type={'secondary'}>
                {'Формат видео - ссылка YouTube или ссылка Вконтакте'}
                <br />
                {'Изменения сохраняются сразу, после добавления видео.'}
            </Paragraph>
            <Row style={{ marginTop: 10 }} gutter={10}>
                {videos.length > 0 ? (
                    videos.map(video => (
                        <Col className={styles.item} key={video.id} span={6}>
                            <img
                                onClick={() =>
                                    video.url.includes('vk.com')
                                        ? setIsVideoVkModal(video.url)
                                        : setIsVideoModal(video.url)
                                }
                                draggable={false}
                                src={
                                    video.url.includes('vk.com')
                                        ? `https://opengraph-app.mamado.su/vkVideoCover?url=${video.url}`
                                        : getYoutubeCover(
                                              getYouTubeID(video.url) ?? ''
                                          )
                                }
                            />
                            <Button
                                danger
                                type={'primary'}
                                shape={'circle'}
                                icon={<DeleteOutlined />}
                                loading={removingVideos.includes(video.id)}
                                size={'small'}
                                onClick={() => handleRemoveVideo(video.id)}
                            />
                        </Col>
                    ))
                ) : (
                    <NotFoundStep>{'Нет добавленных видео'}</NotFoundStep>
                )}
            </Row>
            <Modal
                title={'Добавить видео из YouTube или Вконтакте'}
                open={isAddModal}
                onOk={handleAddVideo}
                onCancel={() => setIsAddModal(false)}
                okText={'Добавить'}
                cancelText={'Отменить'}
            >
                <Form
                    form={form}
                    style={{ marginTop: 25 }}
                    layout={'vertical'}
                    preserve={false}
                >
                    <Form.Item name={'url'}>
                        <Input autoFocus placeholder={'Ссылка на видео'} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Просмотр YouTube видео'}
                open={!!isVideoModal}
                width={600}
                onCancel={() => {
                    setIsVideoModal(false)
                    if (refYouTube.current) {
                        refYouTube.current.internalPlayer?.pauseVideo()
                    }
                }}
                footer={null}
            >
                <YouTube
                    ref={refYouTube}
                    videoId={
                        isVideoModal ? (getYouTubeID(isVideoModal) ?? '') : ''
                    }
                    style={{ marginTop: 15 }}
                    opts={{
                        width: '100%',
                        height: 300
                    }}
                />
            </Modal>
            <Modal
                title={'Просмотр видео Вконтакте'}
                open={!!isVideoVkModal}
                width={600}
                onCancel={() => setIsVideoVkModal(false)}
                footer={null}
                destroyOnClose={true}
            >
                {isVideoVkModal ? (
                    <iframe
                        src={getVkEmbedLink(isVideoVkModal)}
                        width={'552'}
                        height={'337'}
                        allow={
                            'autoplay; encrypted-media; fullscreen; picture-in-picture;'
                        }
                        style={{ border: 'none' }}
                        allowFullScreen
                    />
                ) : null}
            </Modal>
            <NextStepButton />
        </>
    )
}
